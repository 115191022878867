'use client';
import React, { ReactNode } from 'react';
import { AppBar, IconButton, Typography, Box, SxProps, Theme, useTheme, Button, SvgIcon } from '@mui/material';
import { MAX_SCREEN_WIDTH } from '@/app/constants/layoutConfig';
import CenteredBox from '@/app/base-ui/CenterBox';
import EllipsisTypoGraphyMultiLine from '../EllipsisTypoGraphyMultiLine';
import deepmerge from 'deepmerge';
import { BackIcon } from '@/public/icon';

interface NavBarFullLayoutProps {
    title?: string | React.ReactElement;
    onClose?: () => void;
    rightContent?: ReactNode;
    sx?: SxProps;
    icon?: React.ReactNode;
}

const NavBar: React.FC<NavBarFullLayoutProps> = ({ onClose, title = '커뮤니티', rightContent, icon, sx = {} }) => {
    const theme = useTheme();
    return (
        <AppBar
            sx={deepmerge<SxProps<Theme>>(
                {
                    ...defaultStyles.appBar,
                    padding: { mobile: '14px 20px', tablet: '14px 50px' },
                },
                sx
            )}
        >
            <Box sx={defaultStyles.leftContainer}>
                {onClose && (
                    <IconButton
                        onClick={onClose}
                        sx={{ m: -2, cursor: 'pointer' }}
                    >
                        {icon ?? <BackIcon color={theme.palette.grayscale.gray07} />}
                    </IconButton>
                )}
                <CenteredBox sx={defaultStyles.titleContainer}>
                    {typeof title === 'string' ? (
                        <EllipsisTypoGraphyMultiLine
                            variant="h2_20Bold"
                            color="grayscale.gray07"
                            text={title as string}
                        />
                    ) : (
                        title
                    )}
                </CenteredBox>
            </Box>
            <Box sx={defaultStyles.rightContainer}>{rightContent}</Box>
        </AppBar>
    );
};

const defaultStyles: Record<string, SxProps<Theme>> = {
    appBar: {
        top: 0,
        left: 0,
        right: 0,
        margin: '0 auto',
        maxWidth: MAX_SCREEN_WIDTH,
        position: 'fixed',
        width: '100%',
        fontSize: '20px',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        backgroundColor: '#FFFFFF',
        zIndex: 1000,
        height: '48px',
        boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.04)',
    },
    leftContainer: {
        display: 'flex',
        alignItems: 'center',
        flex: 1,
        gap: '10px',
    },
    titleContainer: {
        justifyContent: 'space-between',
        width: '100%',
        height: '48px',
        borderRadius: 8,
    },
    rightContainer: {
        display: 'flex',
        alignItems: 'center',
        color: '',
    },
};

export default NavBar;
